<template lang="pug">
v-container.loginContainer.brand(fluid, fill-height)
    v-row(align='center', justify='center')
        v-fade-transition(hide-on-leave)
            v-col(text-center, cols='8', md='6', lg='4', align-self='center')
                v-row(justify='center', align='center')
                    v-col.loginForm.pt-2.pb-6.white.elevation-8.rounded(cols='12')
                        //- logo in login box
                        v-row(justify='center', align='center')
                            v-col(cols='10')
                                v-img.mt-6.mb-0.logo(src='/DCLogo.jpg', height='64', contain)
                        
                        v-row(justify='center', align='center')
                            v-col(cols='10')
                                v-btn.brandContrast--text(light, color='brand', block, large, @click='login', :disabled='disabled') Login
                        v-row(v-if='loginError')
                            v-col.text-center
                                span.error--text {{ loginError  }}
</template>

<script>
export default {
    data () {
        return {
            disabled: false,
        };
    },
    computed: {
        loginError () {
            return this.$store.state.loginError;
        }
    },
    methods: {
        login () {
            this.disabled = true;
            this.$store.dispatch('login', this.$route.query?.redirect);
        }
    },
    mounted () {
        window.loginvm = this;
    }
};
</script>
